<template>
    <NavigationBar />
    <div class="bg-white h-screen dark:bg-gray-900">

      <Sidebar />

      <div class="grid grid-cols-12 gap-4">
        <div class="col-start-5 col-span-4">
          <form class="flex flex-col pt-3 md:pt-8" onsubmit="event.preventDefault();">

            <div class="flex flex-col pt-4">
              <w-input v-model="username" type="text">E-Mail / Username</w-input>
            </div>

            <div class="flex flex-col pt-4">
              <w-input v-model="language" type="text">Language</w-input>
            </div>

            <!--<div class="flex flex-col pt-4">
                <label for="password" class="text-lg">Password</label>
                <input v-model="password" type="password" id="password" placeholder="Password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline" :class="{ 'border-red-500': wrongPassword, 'bg-red-50': wrongPassword }">
            </div>-->

            <div class="flex flex-col pt-4">
              <w-btn @click="updateUser" :loading="buttonLoading">Save</w-btn>
            </div>

          </form>
        </div>
      </div>
  </div>
</template>

<script>

import NavigationBar from '@/components/NavigationBar.vue';
import { WBtn, WInput } from '@wirecore/wirecomponents';
import Sidebar from './../components/sidebar.vue';

export default {
  name: 'Settings',
  components: {
    'NavigationBar': NavigationBar,
    WBtn,
    WInput,
    Sidebar
  },
  data(){
      return {
        buttonLoading: false,
        username: '',
        firstname: '',
        lastname: '',
        city: '',
        country: '',
        language: ''
      }
  },
  mounted(){

    this.$store.dispatch('getUser').then(response => {
      
      this.username = response.username;
      this.language = response.language;

    });

  },
  methods: {
    updateUser(){

      this.buttonLoading = true;

      let data = {
        'username': this.username,
        'firstname': this.firstname,
        'lastname': this.lastname,
        'city': this.city,
        'country': this.country,
        'language': this.language
      };

      this.$store.dispatch('updateUser', data).then(() => {
        
        this.buttonLoading = false;
        this.$router.push({ path: '/' });

      });

    },
  }
}
</script>

<style>
</style>