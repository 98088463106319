<template>
  <NavigationBar />

  <div class="min-h-screen flex flex-row bg-gray-100 dark:bg-gray-900">

    <Sidebar />

    <div class="container mx-auto pt-16">
      
      <w-btn @click="addCategoryModal">New Category</w-btn>

      <w-table
        :headers="[
          { 'text': 'ID', 'value': 'id' },
          { 'text': 'Category', 'value': 'category_text' },
          { 'text': 'Color', 'value': 'color'},
          { 'text': 'Subscription', 'value': 'subscription'},
          { 'text': 'Translations', 'value': 'translation_count' },
          { 'text': 'Action' }
        ]"
        :items="categories"
        @rowclick="handleRowClick"
      >
        <template #actions>
          <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
        </template>
      </w-table>

    </div>

    <w-dialog ref="dialog">
      <w-input v-model="selectedCategory.color">Color</w-input>
      <w-select :options="[{ name: 'Aktiv', value: true }, { name: 'Inaktiv', value: false }]" v-model="selectedCategory.subscription">Subscription</w-select>
      <div v-if="selectedCategory.id != null">
        <w-select :options="[{ name: 'de', value: 'de' }, { name: 'en', value: 'en' }]" v-model="newTranslation.lang">Lang</w-select>
        <w-textarea v-model="newTranslation.text"></w-textarea>
        <w-btn @click="addTranslation">Add</w-btn>
        <hr>
        <div v-for="(item, index) in selectedCategory.Translations" :key="index">
          <w-textarea v-model="item.text">{{ item.lang }}</w-textarea>
        </div>
      </div>
      <w-btn @click="saveCategory">Save</w-btn>
    </w-dialog>

  </div>

</template>

<script>

import NavigationBar from '@/components/NavigationBar.vue';
import { WTable, WDialog, WTextarea, WSelect, WBtn, WInput } from '@wirecore/wirecomponents';
import Sidebar from './../components/sidebar.vue';

export default {
  name: 'Categories',
  components: {
    'NavigationBar': NavigationBar,
    WTable,
    WDialog,
    WTextarea,
    WSelect,
    WBtn,
    Sidebar,
    WInput
  },
  data(){
      return {
        categories: null,
        selectedCategory: {
          id: null,
          color: null,
          subscription: null,
          Translations: []
        },
        newTranslation: {
          lang: null,
          text: ''
        }
      }
  },
  mounted(){
    this.init();
  },
  methods: {
    init(){

      this.$store.dispatch('getCategories').then(response => {
      
        response.forEach(function(value){

          // category text
          if(value.Translations.length > 0){
            value.category_text = value.Translations[0].text;
          }

          // translation count
          value.translation_count = value.Translations.length;

        });

        this.categories = response;

      });

    },
    handleRowClick(item){

      this.selectedCategory = item;
      console.log(item);

      this.$refs.dialog.show();

    },
    addCategoryModal(){
      this.selectedCategory.color = '';
      this.selectedCategory.subscription = false;
      this.selectedCategory.Translations = [];
      this.$refs.dialog.show();
    },
    addTranslation(){

      this.$store.dispatch('addCategoryTranslation', { categoryId: this.selectedCategory.id, translationData: this.newTranslation }).then(response => {

        console.log(response);
        this.$refs.dialog.hide();
        this.init();

      });

    },
    addCategory(){
      this.$store.dispatch('addCategory').then(response => {

        console.log(response);
        this.init();

      });
    },
    saveCategory(){

      let that = this;

      if(this.selectedCategory.id == null){
        // add category
        that.$store.dispatch('addCategory', { categoryData: { color: this.selectedCategory.color, subscription: this.selectedCategory.subscription } }).then(response => {
          
          console.log(response);
          that.init();

        });
      } else {
        // update category
        that.$store.dispatch('editCategory', { categoryId: that.selectedCategory.id, categoryData: { color: this.selectedCategory.color, subsscription: this.selectedCategory.subscription } }).then(response => {
          
          console.log(response);
          // update translations
          this.selectedCategory.Translations.forEach(function(value){

            that.$store.dispatch('editCategoryTranslation', { categoryId: that.selectedCategory.id, translationId: value.id, translationData: { text: value.text, lang: value.lang } }).then(response => {

              console.log(response);
              that.init();

            });

          });

        });
      }

      this.$refs.dialog.hide();

    }
  }
}
</script>

<style>
</style>