<template>
  <NavigationBar />

  <div class="min-h-screen flex flex-row bg-gray-100 dark:bg-gray-900">

    <Sidebar />

    <div class="container mx-auto pt-16">

      <div class="grid grid-cols-4 gap-4 mb-5">

        <w-card v-for="category in categories" :key="category.id" class="text-white">
          <p>{{ category.Translations[0].text }}: {{ category.question_count }}</p>
        </w-card>

      </div>
      
      <w-btn @click="addQuestion">New Question</w-btn>

      <w-checkbox v-model="filterUncomplete">Filter uncomplete</w-checkbox>

      <w-table
        :headers="[
          { 'text': 'ID', 'value': 'id' },
          { 'text': 'Question', 'value': 'question_text' },
          { 'text': 'Category', 'value': 'category_name'},
          { 'text': 'Translations', 'value': 'translation_count' },
          { 'text': 'Action' }
        ]"
        :items="questions"
        @rowclick="handleRowClick"
      >
        <template #actions>
          <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
        </template>
      </w-table>

    </div>

    <w-dialog ref="dialog">
      <w-select :options="categorieOptions" v-model="selectedQuestion.category_id">Category</w-select>
      <w-select :options="[{ name: 'de', value: 'de' }, { name: 'en', value: 'en' }]" v-model="newTranslation.lang">Lang</w-select>
      <w-textarea v-model="newTranslation.text"></w-textarea>
      <w-btn @click="addTranslation">Add</w-btn>
      <hr>
      <div v-for="(item, index) in selectedQuestion.Translations" :key="index">
        <w-textarea v-model="item.text">{{ item.lang }}</w-textarea>
      </div>
      <w-btn @click="saveQuestion">Save</w-btn>
    </w-dialog>

  </div>

</template>

<script>

import NavigationBar from '@/components/NavigationBar.vue';
import { WTable, WDialog, WTextarea, WSelect, WBtn, WCheckbox, WCard } from '@wirecore/wirecomponents';
import Sidebar from './../components/sidebar.vue';

export default {
  name: 'Questions',
  components: {
    'NavigationBar': NavigationBar,
    WTable,
    WDialog,
    WTextarea,
    WSelect,
    WBtn,
    Sidebar,
    WCheckbox,
    WCard
  },
  data(){
      return {
        filterUncomplete: false,
        categorieOptions: [],
        categories: [],
        questions: null,
        selectedQuestion: {
          Translations: []
        },
        newTranslation: {
          lang: null,
          text: ''
        }
      }
  },
  mounted(){

    let that = this;
    this.$store.dispatch('getCategories').then(response => {
      response.forEach(function(value){
        that.categorieOptions.push({ name: value.Translations[0].text, value: value.id })
      });
    });

    this.init();

  },
  watch: {
    filterUncomplete(newState) {

      if(newState == true){

        let filteredQuestions = [];

        this.questions.forEach(function(value){
          if(value.category_id == null || value.Translations.length < 2){
            filteredQuestions.push(value);
          }
        });

        this.questions = filteredQuestions;

      } else {

        this.init();

      }

    }
  },
  methods: {
    init(){

      this.$store.dispatch('getQuestions').then(response => {
      
        response.forEach(function(value){

          // question text
          if(value.Translations.length > 0){
            value.question_text = value.Translations[0].text;
          }

          // translation count
          value.translation_count = value.Translations.length;

          // category text
          if(value.Category != null){
            value.category_name = value.Category.Translations[0].text;
          } else {
            value.category_name = '';
          }
        });

        this.questions = response;

      });

      let that = this;
      this.$store.dispatch('getCategories').then(response => {
        that.categories = response;
      });

    },
    handleRowClick(item){

      this.selectedQuestion = item;
      console.log(item);

      this.$refs.dialog.show();

    },
    addTranslation(){

      this.$store.dispatch('addQuestionTranslation', { questionId: this.selectedQuestion.id, translationData: this.newTranslation }).then(response => {

        console.log(response);
        this.$refs.dialog.hide();
        this.init();

      });

    },
    addQuestion(){
      this.$store.dispatch('addQuestion').then(response => {

        console.log(response);
        this.init();

      });
    },
    saveQuestion(){

      let that = this;
      // update question
      that.$store.dispatch('editQuestion', { questionId: that.selectedQuestion.id, questionData: { category_id: this.selectedQuestion.category_id } }).then(response => {
        console.log(response);
      });

      // update translations
      this.selectedQuestion.Translations.forEach(function(value){

        that.$store.dispatch('editQuestionTranslation', { questionId: that.selectedQuestion.id, translationId: value.id, translationData: { text: value.text, lang: value.lang } }).then(response => {

          console.log(response);
          that.init();

        });

      });

      this.$refs.dialog.hide();

    }
  }
}
</script>

<style>
</style>