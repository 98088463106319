<template>
  <w-navbar>
    <div class="flex items-center h-full">
    </div>
    <div class="flex items-center h-full text-sm">
        <div class="flex items-center h-full">
            <div class="topbar-item">
                <img @click="openMenu()" src="https://via.placeholder.com/50" class="rounded-full" />
                <WMenu ref="userMenu" position="right">
                  <div class="menu-item">
                    <router-link to="/settings">Persönliche Einstellungen</router-link>
                  </div>
                  <div class="menu-item">
                    <a @click="logout()" style="cursor: pointer;">Logout</a>
                  </div>
                </WMenu>
            </div>
        </div>
    </div>
  </w-navbar>
</template>

<script>

import { WNavbar } from '@wirecore/wirecomponents';
import Menu from './Menu.vue';
import 'boxicons';

export default {
  name: 'NavigationBar',
  components: {
    WNavbar,
    'WMenu': Menu
  },
  data(){
    return {
      
    }
  },
  mounted() {

    document.querySelector('html').classList.add('dark');

  },
  methods: {
        openMenu(){
            if(this.$refs.userMenu.getState() == true){
                this.$refs.userMenu.close();
            } else {
                this.$refs.userMenu.open();
            }
        },
        openModuleMenu(){
            if(this.$refs.moduleMenu.getState() == true){
                this.$refs.moduleMenu.close();
            } else {
                this.$refs.moduleMenu.open();
            }
        },
        logout(){
          this.$store.dispatch('removeJwtToken');
          this.$router.push({ path: '/login' });
        }
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.topbar {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
}

.topbar-item {
    margin: 0.5rem;
    position: relative;
}

.left-align {
    float: left;
    display: flex;
    flex-direction: row;
}

.right-align {
    float: right;
    display: flex;
    flex-direction: row;
}
</style>
