import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Settings from "@/views/Settings.vue";
import Questions from '@/views/Questions.vue';
import Categories from '@/views/Categories.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/questions",
    name: "Questions",
    component: Questions,
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;