<template>
  <div class="menu absolute top-15" :class="{ active: isActive, 'right-0': isRight, 'left-0': isLeft }">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'NavigationBar',
  props: {
    position: String
  },
  methods: {
    open(){
      this.$data.isActive = true;
    },
    close(){
      this.$data.isActive = false;
    },
    getState(){
      return this.$data.isActive;
    },
    click(link){
      this.$router.push({
        path: link
      })
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    isRight(){
      if(this.position !== undefined && this.position == 'right'){
        return true;
      } else {
        return false;
      }
    },
    isLeft(){
      if(this.position === undefined || this.position == 'left'){
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>

.menu {
    /*position: absolute;*/
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.25s linear;
    padding: 0.5rem;
}

.menu-item {
    padding: 0.5rem;
}
.menu-item:hover {
    background: #E6E6E6;
    cursor: pointer;
}

.active {
    visibility: visible;
    opacity: 1;
}

</style>
