<template>
  <NavigationBar />
  <div class="bg-white h-screen pt-16 dark:bg-gray-900">

    <Sidebar />
      
  </div>
</template>

<script>

import NavigationBar from '@/components/NavigationBar.vue';
import Sidebar from './../components/sidebar.vue';

export default {
  name: 'Home',
  data(){
      return {
        
      }
  },
  components: {
    'NavigationBar': NavigationBar,
    Sidebar
  }
}
</script>

<style>
</style>