<template>
	<w-sidebar :items="[
      { text: 'Dashboard', link: '/' },
      { text: 'Questions', link: '/questions' },
      { text: 'Categories', link: '/categories' },
    ]" 
  />
</template>

<script>

import { WSidebar } from '@wirecore/wirecomponents';

export default {
	name: 'Sidebar',
  components: {
    WSidebar
  }
}
</script>