import { createApp } from 'vue'
import { store } from './store'
import App from './App.vue'
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import './assets/tailwind.css';

import plugin from '@wirecore/wirecomponents'
import '@wirecore/wirecomponents/dist/wirecomponents.css'

router.beforeEach((to, from, next) => {

    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    let currentJwt = store.getters.getJwt;

    if(authRequired == false || currentJwt != ''){
        next();
    } else {
        store.dispatch('refreshJwt').then(() => {
                
            currentJwt = store.getters.getJwt;
            if(currentJwt != ''){
                next();
            } else {
                next('/login');
            }

        });
    }

});

createApp(App).use(store).use(router).use(VueAxios, axios).use(plugin).mount('#app')
