import { createStore } from 'vuex'
import axios from 'axios'

const nhie_backend_url = process.env.VUE_APP_NHIE_BACKEND_URL;

export const store = createStore({
  state () {
    return {
      jwt: '',
      jwtRefreshTask: null,
      user: null,
      questions: null,
      categories: null
    }
  },
  getters: {
    getJwt(state){
      return state.jwt;
    },
    getUser(state){
      return state.user;
    },
    getJwtRefreshTask(state){
      return state.jwtRefreshTask;
    },
    getQuestions(state){
      return state.questions;
    },
    getCategories(state){
      return state.categories;
    },
  },
  mutations: {
    SET_JWT_TOKEN(state, token){
      state.jwt = token;
    },
    SET_JWT_REFRESH_TASK(state, task){
      state.jwtRefreshTask = task;
    },
    setUser(state, user){
      state.user = user;
    },
    UPDATE_USER(state, data){
      state.user.username = data.username;
      state.user.language = data.language;
    },
    UPDATE_QUESTIONS(state, data){
      state.questions = data;
    },
    UPDATE_CATEGORIES(state, data){
      state.categories = data;
    },
  },
  actions: {
    setJwtToken({ commit, dispatch }, jwt){
      commit('SET_JWT_TOKEN', jwt);
      const refreshTask = setTimeout(() => dispatch('refreshJwt'), 600000);
      commit('SET_JWT_REFRESH_TASK', refreshTask);
    },
    async refreshJwt({ commit, dispatch }){

      const url = nhie_backend_url + 'auth/refreshToken';
      await axios.get(url, { withCredentials: true })
      .then(response => {
        commit('SET_JWT_TOKEN', response.data);
      }).catch(response => {
        console.log(response);
      });
      const refreshTask = setTimeout(() => dispatch('refreshJwt'), 600000);
      commit('SET_JWT_REFRESH_TASK', refreshTask);
    },
    deleteJwtToken({ commit }){
      commit('SET_JWT_TOKEN', '');
    },
    async getUser({ getters, commit }){

      let user = getters.getUser;

      if(user == null){
        const jwt = getters.getJwt;
        const url = nhie_backend_url + 'users/getCurrentUser';
        await axios.get(url, { headers: {
          "Authorization": jwt.token
        }}).then(response => {
          commit('setUser', response.data);
          user = getters.getUser;
        });
      } else {
        user = getters.getUser;
      }
      return user;
    },
    async updateUser({ getters, commit }, data){

      commit('UPDATE_USER', data);

      let user = getters.getUser;
      const jwt = getters.getJwt;

      const url = nhie_backend_url + 'users/edit';
      await axios.put(url, JSON.stringify(user), { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      } }).then(response => {
        commit('setUser', response.data);
        user = getters.getUser;
      });

      return user;

    },
    async getCategories({ getters, commit }){

      let categories = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'categories';
      await axios.get(url, { headers: {
        "Authorization": jwt.token
      }}).then(response => {
        commit('UPDATE_CATEGORIES', response.data);
        categories = response.data;
      });

      return categories;

    },
    async addCategory({ getters }, { categoryData }){

      let category = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'categories';
      await axios.post(url, categoryData, { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      }}).then(response => {
        category = response.data;
      });

      return category;

    },
    async editCategory({ getters }, { categoryId, categoryData }){

      let category = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'categories/' + categoryId;
      await axios.put(url, categoryData, { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      }}).then(response => {
        category = response.data;
      });

      return category;

    },
    async addCategoryTranslation({ getters }, { categoryId, translationData }){

      let translation = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'categories/' + categoryId + '/translations';
      await axios.post(url, translationData, { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      }}).then(response => {
        translation = response.data;
      });

      return translation;

    },
    async editCategoryTranslation({ getters }, { categoryId, translationId, translationData }){

      let translation = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'categories/' + categoryId + '/translations/' + translationId;
      await axios.put(url, translationData, { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      }}).then(response => {
        translation = response.data;
      });

      return translation;

    },
    async getQuestions({ getters, commit }){

      let questions = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'questions?categories=1&translations=1';
      await axios.get(url, { headers: {
        "Authorization": jwt.token
      }}).then(response => {
        commit('UPDATE_QUESTIONS', response.data);
        questions = response.data;
      });

      return questions;

    },
    async addQuestion({ getters }){

      let question = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'questions';
      await axios.post(url, {}, { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      }}).then(response => {
        question = response.data;
      });

      return question;

    },
    async editQuestion({ getters }, { questionId, questionData }){

      let question = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'questions/' + questionId;
      await axios.put(url, questionData, { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      }}).then(response => {
        question = response.data;
      });

      return question;

    },
    async addQuestionTranslation({ getters }, { questionId, translationData }){

      let translation = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'questions/' + questionId + '/translations';
      await axios.post(url, translationData, { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      }}).then(response => {
        translation = response.data;
      });

      return translation;

    },
    async editQuestionTranslation({ getters }, { questionId, translationId, translationData }){

      let translation = null;

      const jwt = getters.getJwt;
      const url = nhie_backend_url + 'questions/' + questionId + '/translations/' + translationId;
      await axios.put(url, translationData, { headers: {
        "Authorization": jwt.token,
        'Content-Type': 'application/json'
      }}).then(response => {
        translation = response.data;
      });

      return translation;

    },
  }
})