<template>
  <div class="bg-white h-screen dark:bg-gray-900">

        <!-- Login Section -->
        <div class="w-full md:w-1/2 flex flex-col">

            <div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
                
                <form class="flex flex-col pt-3 md:pt-8" onsubmit="event.preventDefault();">
                    <div class="flex flex-col pt-4">
                        <w-input v-model="username" type="text">E-Mail</w-input>
                    </div>
    
                    <div class="flex flex-col pt-4">
                        <w-input v-model="password" type="password">Password</w-input>
                    </div>
    
                    <div class="flex flex-col pt-4">
                        <w-btn @click="login" :loading="buttonLoading">Login</w-btn>
                    </div>

                </form>
                <div class="text-center pt-12 pb-12">
                    <p>Don't have an account? <a href="register.html" class="underline font-semibold">Register here.</a></p>
                </div>
            </div>

        </div>

        <!-- Image Section -->
        <div class="w-1/2 shadow-2xl">
            <img class="object-cover w-full h-screen hidden md:block" src="https://source.unsplash.com/IXUM4cJynP0">
        </div>
    </div>
</template>

<script>

import { WBtn, WInput } from '@wirecore/wirecomponents';

export default {
  name: 'Login',
  components: {
    WBtn,
    WInput
  },
  data(){
      return {
          username: '',
          password: '',
          wrongPassword: false,
          buttonLoading: false
      }
  },
  methods: {
    login(){

        let username = this.username;
        let password = this.password;

        const nhie_backend_url = process.env.VUE_APP_NHIE_BACKEND_URL;
        const url = nhie_backend_url + 'auth/login';

        this.buttonLoading = true;

        this.axios.post(url, {
            'username': username,
            'password': password
        }, { withCredentials: true }).then(response => {
            this.buttonLoading = false;
            if(response.status == 200){
                let jwt = response.data;
                this.$store.dispatch('setJwtToken', jwt);
                this.$router.push({ path: '/' })
            }
        }).catch((error) => {
            this.buttonLoading = false;
            console.log(error);
            this.wrongPassword = true;
        });

    }
  }
}

</script>

<style>
</style>